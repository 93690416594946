import { Component, OnInit } from '@angular/core';
import {SharedService} from '../../shared.service';
import {Review} from '../../review';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {

  reviews: Review[];
  review: Review = new Review();
  focus: any;
  focus1: any;

  constructor(private service: SharedService) { }

  ngOnInit(): void {
    this.getReviews();
  }

  sendReview() {
    this.service.sendReview(this.review).subscribe(() => {
      this.getReviews();
      this.review = new Review();
    });
  }

  getReviews(){
    this.service.getReviews().subscribe(res => {
      this.reviews = res
      console.log(this.reviews)
    });
  }
}
