import { Component, OnInit } from '@angular/core';
import {SharedService} from '../../shared.service';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {
  focus: any;
  focus1: any;
  email: string | undefined;
  subject: string | undefined;
  body: string | undefined;
  lat = 51.678418;
  lng = 7.809007;

  constructor(private service: SharedService) { }

  ngOnInit() {}

  sendContactEmail(){
    let data = {
      email: this.email,
      subject: this.subject,
      body: this.body
    }

    this.service.sendContactEmail(data).subscribe(res =>{
      console.log(res);
    })

  }

}
