import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SharedService} from '../../shared.service';
import {noop} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {GalleryItem, ImageItem} from 'ng-gallery';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit, AfterViewInit {
    focus: any;
    focus1: any;
    email: string | undefined;
    subject: string | undefined;
    body: string | undefined;
    lat = 46.333979;
    lng = 23.507416;
    basePath = './assets/img/';
    imgSource: string[] = [
        'Atv_1.jpeg',
        'Atv_2.jpeg',
        'Atv_3.jpeg',
        'Atv_4.jpeg',
        'Atv_5.jpeg',
        'Atv_6.jpeg',
        'Atv_7.jpeg',
        'Atv_8.jpeg',
        'Atv_9.jpeg',
        'Atv_10.jpeg',
        'Atv_11.jpeg',
        'Atv_12.jpeg',
        'Atv_13.jpeg',
        'image0.jpeg',
        'image1.jpeg',
        'image2.jpeg',
        'image3.jpeg',
        'image4.jpeg',
        'image5.jpeg',
        'image6.jpeg',
        'image7.jpeg',
    ];

    @ViewChild('targetRes', { static: true }) inputRes: ElementRef;
    @ViewChild('target', { static: true }) input: ElementRef;
    images: GalleryItem[];


    constructor(private service: SharedService, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.images = this.imgSource.map(item =>
            new ImageItem({ src: this.basePath + item, thumb:   this.basePath + item })
        );
    }

    ngAfterViewInit(): void {
        this.route.snapshot.paramMap.get('id') === "1"
            ? this.inputRes.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" })
            : noop();
        this.route.snapshot.paramMap.get('id') === "2"
            ? this.input.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" })
            : noop();
    }

    sendContactEmail() {
        let data = {
            email: this.email,
            subject: this.subject,
            body: this.body
        }

        this.service.sendContactEmail(data).subscribe(res => {
            console.log(res);
        })

    }

    scroll(target: HTMLDivElement) {
        target.scrollIntoView();
    }
}
