import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-package1',
  templateUrl: './package1.component.html',
  styleUrls: ['./package1.component.scss']
})
export class Package1Component implements OnInit {



  constructor() { }

  ngOnInit(): void {
  }

}
