import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { ProfileComponent } from './examples/profile/profile.component';
import { SignupComponent } from './examples/signup/signup.component';
import { LandingComponent } from './examples/landing/landing.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import {PackagesComponent} from './examples/packages/packages.component';

import {ReviewComponent} from './examples/review/review.component';
import {Package1Component} from './examples/packages/package1/package1.component';
import {Package2Component} from './examples/packages/package2/package2.component';
import {Package3Component} from './examples/packages/package3/package3.component';
import {Package4Component} from './examples/packages/package4/package4.component';
import {TracksComponent} from './examples/tracks/tracks.component';
import {AboutComponent} from './examples/about/about.component';
import {BookingComponent} from './examples/booking/booking.component';
import {InvestorComponent} from './examples/investor/investor.component';


const routes: Routes =[
    { path: '', redirectTo: 'landing', pathMatch: 'full' },
    { path: 'home',             component: ComponentsComponent },
    { path: 'user-profile',     component: ProfileComponent },
    { path: 'signup',           component: SignupComponent },
    { path: 'landing',          component: LandingComponent },
    { path: 'landing/:id',          component: LandingComponent},
    { path: 'nucleoicons',      component: NucleoiconsComponent },
    { path: 'packages',      component: PackagesComponent },
    { path: 'review',      component: ReviewComponent },
    { path: 'packages/package1',      component: Package1Component },
    { path: 'packages/package2',      component: Package2Component },
    { path: 'packages/package3',      component: Package3Component },
    { path: 'packages/package4',      component: Package4Component },
    { path: 'tracks',      component: TracksComponent },
    { path: 'about',      component: AboutComponent },
    { path: 'booking',      component: BookingComponent },
    { path: 'funder',      component: InvestorComponent },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
