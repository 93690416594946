import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from 'rxjs';
import {environment} from '../environments/environment';
import {Review} from './review';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  readonly APIUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  sendContactEmail(val: any){
    return this.http.post(this.APIUrl + 'email/contact', val);
  }

  sendReview(data: { review: string; name: string; email: string }) {
    return this.http.post(this.APIUrl + 'review/add', data);
  }


  getReviews(): Observable<Review[]>{
    return this.http.get<Review[]>(this.APIUrl + "review/get");
  }
}
