import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {LandingComponent} from './landing/landing.component';
import {ProfileComponent} from './profile/profile.component';
import {SignupComponent} from './signup/signup.component';
import {AgmCoreModule} from '@agm/core';
import {PackagesComponent} from './packages/packages.component';
import {Package1Component} from './packages/package1/package1.component';
import {Package2Component} from './packages/package2/package2.component';
import {Package3Component} from './packages/package3/package3.component';
import {Package4Component} from './packages/package4/package4.component';
import {RouterModule} from '@angular/router';
import {ReviewComponent} from './review/review.component';
import {AgmSnazzyInfoWindowModule} from '@agm/snazzy-info-window';
import {TracksComponent} from './tracks/tracks.component';
import {AboutComponent} from './about/about.component';
import {BookingComponent} from './booking/booking.component';
import {InvestorComponent} from './investor/investor.component';
import {GalleryModule} from 'ng-gallery';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAFSqzNV6-eToeZsqRuGV_qv0ceviemUrM'
        }),
        RouterModule,
        AgmSnazzyInfoWindowModule,
        GalleryModule
    ],
    declarations: [
        LandingComponent,
        SignupComponent,
        ProfileComponent,
        PackagesComponent,
        Package1Component,
        Package2Component,
        Package3Component,
        Package4Component,
        ReviewComponent,
        TracksComponent,
        AboutComponent,
        BookingComponent,
        InvestorComponent
    ]
})
export class ExamplesModule {
}
